import { Delete } from '@mui/icons-material';

import { InputBlock } from '@shared/input-block';
import Required from '@shared/required';
import { SelectSearch } from '@shared/select-search';
import { CloseButton, Error, FieldWrapper, InlineWrapper, MainBtn, Note, SpanLabel } from '@shared/styled';
import { PhoneInput } from 'react-international-phone';
import { useEffect, useRef, useState } from 'react';
import type { ContactMethod } from '~/types';
import { addHttps } from '~/utils/add-https';
import { cleanupIgFbUrl } from '~/utils/cleanup-ig-fb-url';
import { contactUrlPlaceholder } from '~/utils/constants';
import { getCountry } from '~/utils/get-country';
import { stripHttps } from '~/utils/strip-https';
import { validateEmail } from '~/utils/validate-email';
import { validateUrl } from '~/utils/validate-url';

import styles from './styles.module.css';
import { isPhoneValid } from '~/utils/is-phone-valid';

const errorByType = {
  website: 'Please enter a valid URL',
  facebook: 'Please enter your Facebook page',
  instagram: 'Please enter your Instagram handle',
  whatsapp: 'Please enter a valid phone number',
  phone: 'Please enter a valid phone number',
  email: 'Please enter a valid email',
};

export const ContactMethods = ({
  contactMethods: initialMethods,
  updateMethods,
}: {
  contactMethods?: ContactMethod[];
  updateMethods: (contactMethods: ContactMethod[]) => void;
}) => {
  const [formErrors, setFormErrors] = useState(['']);
  const locationData = useRef(getCountry());
  const inputWrapper = useRef<(HTMLDivElement | null)[]>([]);
  const [methods, setMethods] = useState<ContactMethod[]>(
    initialMethods || [
      {
        type: 'email',
        value: '',
      },
    ],
  );

  useEffect(() => {
    validate(methods);
  }, [methods]);

  const handleUpdate = (index: number, data: Partial<ContactMethod>) => {
    const newMethods = [...methods];
    newMethods[index] = { ...newMethods[index], ...data };

    updateMethods(newMethods);
    setMethods(newMethods);
  };

  const validate = (newMethods: ContactMethod[]) => {
    const hasError = [];
    const errors = [];

    for (let index = 0; index < newMethods.length; index++) {
      const { type, value } = newMethods[index];

      if (['phone', 'whatsapp'].includes(type as string)) {
        hasError.push(!isPhoneValid(value));
      } else if (type === 'website') {
        hasError.push(!validateUrl(value));
      } else if (type === 'email') {
        hasError.push(!validateEmail(value));
      } else {
        hasError.push(!value.length);
      }

      errors[index] = hasError[index] && value !== '' ? errorByType[type!] : '';
    }

    setFormErrors(errors);

    return !hasError.filter(Boolean).length;
  };

  const handleAdd = () => {
    setMethods([...methods, { type: 'email', value: '' }]);
  };

  const handleDelete = (index: number) => {
    const newMethods = [...methods];
    newMethods.splice(index, 1);

    updateMethods(newMethods);
    setMethods(newMethods);
  };

  return (
    <>
      <SpanLabel>
        How to contact the organiser <Required />
      </SpanLabel>
      <Note>What is the best way to reach you with questions</Note>
      {methods.map(({ type, value }, index) => (
        <FieldWrapper noMarginBottom key={`contact-method-${index}`}>
          <InlineWrapper className={styles.StyledInlineWrapper}>
            <SpanLabel>Type</SpanLabel>
            {methods.length > 1 && (
              <CloseButton className={styles.StyledCloseButton} onClick={() => handleDelete(index)}>
                <Delete />
              </CloseButton>
            )}
          </InlineWrapper>

          <SelectSearch<ContactMethod['type']>
            placeholder="Social media, email or phone number"
            fullWidth
            value={type}
            options={[
              { label: 'Email', value: 'email' },
              { label: 'Phone Number', value: 'phone' },
              { label: 'Website', value: 'website' },
              { label: 'Facebook', value: 'facebook' },
              { label: 'Instagram', value: 'instagram' },
              { label: 'WhatsApp', value: 'whatsapp' },
            ]}
            onChange={(newVal) => {
              handleUpdate(index, { type: newVal?.value, value: '' });
              setTimeout(() => {
                inputWrapper.current[index]?.querySelector('input')?.focus();
              });
            }}
          />
          <div className={styles.ValueWrapper} ref={(ref) => (inputWrapper.current[index] = ref)}>
            {type === 'phone' || type === 'whatsapp' ? (
              <div className={styles.PhoneWrapper}>
                <PhoneInput
                  defaultCountry={locationData.current.country.abbr.toLowerCase()}
                  placeholder="Enter phone number"
                  value={value}
                  onChange={(val) => handleUpdate(index, { value: val })}
                />
              </div>
            ) : (
              <InputBlock
                prefix={
                  ['facebook', 'instagram'].includes(type as string)
                    ? `https://${type}.com/`
                    : type === 'website'
                      ? 'https://'
                      : ''
                }
                placeholder={type && contactUrlPlaceholder[type]}
                type="string"
                value={['facebook', 'instagram'].includes(type as string) ? cleanupIgFbUrl(value!) : stripHttps(value!)}
                pasteModifier={['facebook', 'instagram'].includes(type as string) ? cleanupIgFbUrl : stripHttps}
                updateValue={(newVal) =>
                  handleUpdate(index, {
                    value: type === 'website' ? addHttps(stripHttps(newVal) as string) : cleanupIgFbUrl(newVal),
                  })
                }
              />
            )}
            {formErrors[index] && <Error>{formErrors[index]}</Error>}
          </div>
        </FieldWrapper>
      ))}
      <div className={styles.ActionsWrapper}>
        <MainBtn small onClick={handleAdd}>
          Add Contact Method
        </MainBtn>
      </div>
    </>
  );
};
