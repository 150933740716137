import type { ContactMethod } from '~/types';

import { isPhoneValid } from './is-phone-valid';
import { validateEmail } from './validate-email';
import { validateUrl } from './validate-url';

export const hasValidContactMethod = (methods: ContactMethod[]) => methods.some(isValidContactMethod);

export const isValidContactMethod = ({ type, value }: ContactMethod) => {
  if (['phone', 'whatsapp'].includes(type as string)) {
    return isPhoneValid(value);
  } else if (type === 'website') {
    return validateUrl(value);
  } else if (type === 'email') {
    return validateEmail(value);
  } else {
    return !!value.length;
  }
};
