export const cleanupIgFbUrl = (value: string) => {
  const newVal = value
    .replace('https://instagram.com/', '')
    .replace('https://www.instagram.com/', '')
    .replace('https://facebook.com/', '')
    .replace('https://www.facebook.com/', '');

  if (newVal.endsWith('/')) {
    return newVal.substring(0, newVal.length - 1);
  }
  return newVal;
};
