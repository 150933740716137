import locationData from 'moment-timezone/data/meta/latest.json';

export const getCountry = () => {
  let region;
  let city;
  let country;
  let userTimeZone;
  const timeZoneCityToCountry: any = {};
  const { countries, zones } = locationData;
  const typedCountries = countries as any;
  const typedZones = zones as any;

  Object.keys(zones).forEach((z) => {
    const cityArr = z.split('/');
    const city = cityArr[cityArr.length - 1];
    timeZoneCityToCountry[city] = typedCountries[typedZones[z].countries[0]];
  });

  if (Intl) {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var tzArr = userTimeZone.split('/');
    region = tzArr[0];
    city = tzArr[tzArr.length - 1];
    country = timeZoneCityToCountry[city];
  }

  return {
    region,
    city,
    country,
  };
};
